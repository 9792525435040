<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button icon="chevron-back-outline"></ion-back-button>
				</ion-buttons>
				<ion-title>{{ $route.meta.title }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<ion-list>
				<ion-item-group>
					<ion-item-divider>
						<ion-label>Possíveis causas</ion-label>
					</ion-item-divider>

					<ion-item>
						<ion-icon slot="start" name="chevron-forward-circle-outline"></ion-icon>
						<ion-label class="ion-text-wrap">
							URL ou link incorreto
						</ion-label>
					</ion-item>

					<ion-item>
						<ion-icon slot="start" name="chevron-forward-circle-outline"></ion-icon>

						<ion-label class="ion-text-wrap">
							Link quebrado (significa que existia conteúdo neste endereço anteriormente, mas ele foi removido)
						</ion-label>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
	//Ícones Ionic
	import {
		chevronForwardCircleOutline
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"chevron-forward-circle-outline": chevronForwardCircleOutline,
	});

	export default {
		name: "404Index"
	}
</script>

<style scoped>

</style>
